var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: "portalJobRunsTablePage",
        staticClass: "portalJobRunsTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "Started", ascending: 0 },
          modelId: "Id",
          headerTitle: "processes2721",
          tooltipMsg: "manualforproces",
          showActionsColumn: false,
          addNewRecordMsg: "",
        },
        on: {
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "Result",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "job-result",
                      attrs: { title: _vm.formatResult(list.row.Result) },
                    },
                    [
                      list.row.Result == 1
                        ? _c("font-awesome-icon", {
                            staticClass: "job-result-running",
                            attrs: { icon: "running", size: "lg" },
                          })
                        : _vm._e(),
                      list.row.Result == 2
                        ? _c("font-awesome-icon", {
                            staticClass: "job-result-success",
                            attrs: { icon: "check", size: "lg" },
                          })
                        : _vm._e(),
                      list.row.Result == 3
                        ? _c("font-awesome-icon", {
                            staticClass: "job-result-failed",
                            attrs: { icon: "times", size: "lg" },
                          })
                        : _vm._e(),
                      list.row.Result == 4
                        ? _c("font-awesome-icon", {
                            staticClass: "job-result-fixed",
                            attrs: { icon: "check", size: "lg" },
                          })
                        : _vm._e(),
                      _c(
                        "b-badge",
                        {
                          staticClass: "job-run-detail-trigger",
                          attrs: { id: "run-" + list.row.Id },
                        },
                        [_vm._v(_vm._s(_vm.$t("details")))]
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "run-" + list.row.Id,
                            placement: "auto",
                            triggers: "hover",
                            delay: "200",
                            "custom-class": "job-run-details",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _vm._v(_vm._s(_vm.$t("jobinformation"))),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("div", {
                            staticClass: "job-run-detail-container",
                            domProps: { innerHTML: _vm._s(list.row.Details) },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "JobId",
              fn: function (list) {
                return [_vm._v(_vm._s(list.row.JobName))]
              },
            },
            {
              key: "ScheduleId",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(
                      _vm.formatScheduleDescription(
                        list.row.ScheduleDescription
                      )
                    )
                  ),
                ]
              },
            },
            {
              key: "Started",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm._f("dateTime")(list.row.Started)))]
              },
            },
            {
              key: "Duration",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm.formatDuration(list.row.Duration)))]
              },
            },
          ],
          null,
          false,
          2999404637
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }