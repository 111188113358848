

export const tableColumns = [
			{
				model: 'JobId',
				i18n: 'job',
				sortable: true,
				filter: 'dropdown',
				filterOptions: {
					dropdownOptions: [],
				},
			},
			{
				model: 'ScheduleId',
				i18n: 'plan',
				sortable: true,
				filter: 'dropdown',
				filterOptions: {
					dropdownOptions: [],
				},
			},
			{
				model: 'Started',
				i18n: 'started',
				sortable: true,
				filter: 'daterange',
			},
			{
				model: 'Duration',
				i18n: 'duration',
				sortable: true,
				filter: 'numberrange',
			},
			{
				model: 'Result',
				i18n: 'result',
				sortable: true,
				filter: 'dropdown',
				filterOptions: {
					dropdownOptions: [],
				},
			},
		];
