<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		ref="portalJobRunsTablePage"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'Started', ascending: 0 }"
		modelId="Id"
		headerTitle="processes2721"
		tooltipMsg="manualforproces"
		:showActionsColumn="false"
		addNewRecordMsg
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="portalJobRunsTable"
	>
		<template #Result="list">
			<div :title="formatResult(list.row.Result)" class="job-result">
				<font-awesome-icon v-if="list.row.Result == 1" class="job-result-running" icon="running" size="lg"></font-awesome-icon>
				<font-awesome-icon v-if="list.row.Result == 2" class="job-result-success" icon="check" size="lg"></font-awesome-icon>
				<font-awesome-icon v-if="list.row.Result == 3" class="job-result-failed" icon="times" size="lg"></font-awesome-icon>
				<font-awesome-icon v-if="list.row.Result == 4" class="job-result-fixed" icon="check" size="lg"></font-awesome-icon>
				<b-badge :id="`run-${list.row.Id}`" class="job-run-detail-trigger">{{ $t('details') }}</b-badge>
				<b-popover :target="`run-${list.row.Id}`" placement="auto" triggers="hover" delay="200" custom-class="job-run-details">
					<template #title>{{ $t('jobinformation') }}</template>
					<div v-html="list.row.Details" class="job-run-detail-container"></div>
				</b-popover>
			</div>
		</template>

		<template #JobId="list">{{ list.row.JobName }}</template>

		<template #ScheduleId="list">{{ formatScheduleDescription(list.row.ScheduleDescription) }}</template>

		<template #Started="list">{{ list.row.Started | dateTime }}</template>

		<template #Duration="list">{{ formatDuration(list.row.Duration) }}</template>

		<!--<template #filter__Duration>
			 <number-range
				:value="filters.Duration"
				:min="0"
				:max="9999"
				:step="1"
				@changed="handleDurationFilter"
			></number-range>
		</template>-->
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { setDropdownOptions } from '@/components/general/utils';
//import numberRange from '../../components/table/number-range.filter';
import http from '../../services/helpers/http';
import utils from '../../services/helpers/utils';

//import serviceCommon from '../../services/service/common.service';
import serviceEnums from '../../services/service/enums.service';

import { tableColumns } from './portal-job-runs.table-data';

export default {
	name: 'portalJobRunsTable',

	components: {
		TablePage,
		//numberRange,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'PortalJobRunListItem',

			ResultDict: serviceEnums.getEnumDict('portalJobRunResult'),
		};
	},

	async created() {
		this.columns = await this.prepareColumns(tableColumns);
	},

	methods: {
		async prepareColumns(columns) {
			let values = serviceEnums.getEnumForDropdown('portalJobRunResult', true);
			columns = setDropdownOptions(columns, 'Result', values);

			values = await this.getJobsFilterSelectorItems();
			columns = setDropdownOptions(columns, 'JobId', values);

			values = await this.getSchedulesFilterSelectorItems();
			columns = setDropdownOptions(columns, 'ScheduleId', values);

			return columns;
		},

		async getJobsFilterSelectorItems() {
			let resp = await http.get('PortalJob/List');

			return utils.convertItems(
				resp.data,
				function (item) {
					return { value: item.Id, text: item.Name };
				},
				{ value: null, text: '' }
			);
		},

		async getSchedulesFilterSelectorItems() {
			let resp = await http.get('PortalJobSchedule/List');

			const result = utils.convertItems(
				resp.data,
				function (item) {
					return { value: item.Id, text: item.Description };
				},
				{ value: null, text: '' }
			);
			result.push({ value: 0, text: this.$t('manuallystarted') });

			return result;
		},

		formatResult(value) {
			if (value > 0) {
				return this.ResultDict[value].Text;
			}
			return '-';
		},

		refresh() {
			this.tableKey += 1;
		},

		// handleCustomFilter() {
		// 	serviceCommon.setCustomFilter(this.filters, this.$options.name);
		// },

		// handleDurationFilter(durationFilter) {
		// 	//this.filters.Duration = durationFilter;
		// 	this.handleCustomFilter();
		// },

		formatDuration(value) {
			var date = new Date(0);
			date.setSeconds(value);
			var timeString = date.toISOString().substr(11, 8);
			return timeString;
		},

		formatScheduleDescription(value) {
			if (value) {
				return value;
			}

			return this.$t('manuallystarted');
		},
	},
};
</script>
<style lang="scss">
.portalJobRunsTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row > th:nth-child(6) {
		min-width: 100px;
		width: 100px;
	}
	.VueTables__filters-row > th:nth-child(5) {
		min-width: 110px;
		width: 110px;
	}

	.job-result {
		text-align: center;

		.job-result-running {
			color: #20a8d8;
		}

		.job-result-success {
			color: var(--success);
		}

		.job-result-failed {
			color: var(--danger);
		}

		.job-result-fixed {
			color: orange;
		}
	}
}

.b-popover.job-run-details {
	min-width: 900px;

	.popover-body {
		padding: 10px;
	}
}

.job-run-detail-container {
	overflow-y: auto;
	max-height: 700px;

	> table:nth-child(2) {
		td {
			padding-bottom: 1rem;
		}
	}
}

.job-run-detail-trigger {
	margin-left: 15px;
}
</style>
